


































































// @ts-nocheck

import Vue from "vue";
import formSchemaSource from "@/common/form/contact";
export default Vue.extend({
  data() {
    return {
      id: undefined,
      formSchemaSource,
      data: {},
      home: this.$store.state.allData.home[0],
      categorys: [],
      services: [],
      certificates: [],
      partners: [],
      name: null,
      phone: null,
      msg: null,
      infoWindow: undefined,
      map: undefined,
    };
  },
  mounted() {
    this.getAllDatas();
    this.initMap();
  },

  methods: {
    initMap() {
      var lnglat = this.data.lnglat.split(",");
      var map = new AMap.Map("map", {
        resizeEnable: true,
        center: lnglat,
        zoom: 15,
      });
      var marker = new AMap.Marker({
        position: lnglat,
      });
      marker.setMap(map);

      var content = `<div style="padding:10px;">
        <img style="float:left;margin-right:10px;" src="${this.data.map[0].url}">
        ${this.home.address}`;
      var infowindow1 = new AMap.AdvancedInfoWindow({
        content: content,
        offset: new AMap.Pixel(0, -30),
        asOrigin: false,
      });
      infowindow1.open(map, lnglat);
    },

    getAllDatas() {
      let data = this.$store.state.allData;
      this.certificates = data["certificates"];
      this.data = data["contact"][0];
    },

    onSubmit() {
      const { name, phone, msg } = this;
      console.log(name, phone, msg);
      if (!name) {
        return this.$message("请输入姓名");
      }
      if (!phone) {
        return this.$message("请输入手机号");
      }
      // @ts-ignore
      if (phone && phone.length != 11) {
        return this.$message("请输入正确的手机号");
      }
      const form = encodeURIComponent(
        `姓名: ${name} | 电话: ${phone}  | 留言: ${msg}`
      );
      // @ts-ignore
      const url = `https://www.pushplus.plus/send?token=${this.data.token}&template=html&title=休度官网表单&content=${form}`;

      this.$fetch({ url }).then((res: any) => {
        this.$message({ message: "提交成功", type: "success" });
        this.name = null;
        this.phone = null;
        this.msg = null;
      });
    },
  },
});
