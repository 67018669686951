
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "banner": images({}),

            "token": {
                "type": "string",
                "ui": {
                    "label": "微信接收表单token,请勿随意更改"
                }
            },

            "lnglat": {
                "type": "string",
                "ui": {
                    "label": "经纬度"
                }
            },

            "map": images({}),




        }
    }